import React from "react";
import styled from "styled-components";
import products from '../../data/products.json'
import "./Product.css";

const Part1 = styled.div`
  position: relative;

  &::before {
    content: "";
    background: url(${(props) => props.bgImage}) no-repeat center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;


function Product() {
  return (
    <div>
      <section className="section-products" id="products-div">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-8 col-lg-6">
              <div className="header">
                <h3>Featured Product</h3>
                <h2>Popular Products</h2>
              </div>
            </div>
          </div>

          <div  className="row">
            {products.map((product) => (
              <div key={product.id} className="col-md-6 col-lg-4 col-xl-3">
                <div className="single-product">
                  <Part1 className="part-1" bgImage={product.imageUrl}>
                    <div>
                      {/* <ul>
                        <li>
                          <a href="#" aria-label="Add to cart">
                            <i className="fas fa-shopping-cart" />
                          </a>
                        </li>
                        <li>
                          <a href="#" aria-label="Add to wishlist">
                            <i className="fas fa-heart" />
                          </a>
                        </li>
                        <li>
                          <a href="#" aria-label="Add to compare">
                            <i className="fas fa-plus" />
                          </a>
                        </li>
                        <li>
                          <a href="#" aria-label="View details">
                            <i className="fas fa-expand" />
                          </a>
                        </li>
                      </ul> */}
                    </div>
                  </Part1>

                  {/* <div className="part-2">
                    <h3 className="product-title">{product.title}</h3>
                    <h4 className="product-old-price">{product.oldPrice}</h4>
                    <h4 className="product-price">{product.price}</h4>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Product;
