import React, { useEffect } from "react";
import Navbar from "../combonent/Navbar/Navbar";
import Footer from "../combonent/Footer/Footer";
import Contact from "../combonent/contact/Contact";
import Carousel from "../combonent/Carousel/Carousel";

function ContactPage() {
  useEffect(() => {
    const element = document.getElementById("contact-div");
    if (element) {
      const yOffset = -50;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <Navbar />
      <Carousel />
      <Contact />
      <Footer />
    </>
  );
}

export default ContactPage;
