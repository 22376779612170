import React from "react";
import { useNavigate } from "react-router-dom";
import company from "../../data/address.json"

function About() {
  const navigate = useNavigate();
  return (
    <div id="about-div">
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden h-100"
                style={{ minHeight: 400 }}
              >
                <img
                  className="position-absolute w-100 h-100 pt-5 pe-5"
                  src="img/about-1.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <img
                  className="position-absolute top-0 end-0 bg-white ps-2 pb-2"
                  src="img/about-2.jpg"
                  alt=""
                  style={{ width: 200, height: 200 }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                  About Us
                </div>

                <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                  <p className="text-dark mb-2">
                    Kyries Ventures, a group of trading companies with more than
                    25 years of reputation in Textiles and retail segment, is
                    offering services in Consumer Retail industries across the
                    globe. We are expanding our services in business segments of
                    Consumer & Retail, Fabric & Apparel, Electronics, and
                    Hospitality. We have retailers from China, India, America,
                    Spain, and Middle-East. Garment retailers are from Thailand
                    and Turkey.
                  </p>
                </div>
                {/* <a className="btn btn-primary py-2 px-3 me-3" href="">
            Learn More
            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
              <i className="fa fa-arrow-right" />
            </div>
          </a> */}
                <a
                  className="btn btn-outline-primary py-2 px-3"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/contact");
                  }}
                >
                  Contact Us
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-4 justify-content-center">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
            <div className="text-center p-4 pt-0">
              <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                <small>Our Vision</small>
              </div>
              <h5 className="mb-3"></h5>
              <p>{company.vision}</p>
              {/* <div>Ph:</div> */}
              {/* <p className="">Email:</p> */}
              {/* <div className="causes-progress bg-white p-3 pt-2">
                        <div className=" justify-content-between"></div>
                      </div> */}
            </div>
            <div className="position-relative mt-auto">
              <img
                className="img-fluid"
                // src="img/courses-1.jpg"
                src=""
                alt=""
              />
              {/* <div className="causes-overlay">
                <a className="btn btn-outline-primary" href="">
                  View More
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
            <div className="text-center p-4 pt-0">
              <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                <small>Our Mission</small>
              </div>
              <h5 className="mb-3"></h5>
              {/* <p>ADDRESS</p> */}
              {/* <div>Ph:</div>*/}
              <p className="">{company.mission}</p>
              {/* <div className="causes-progress bg-white p-3 pt-2">
                        <div className=" justify-content-between"></div>
                      </div> */}
            </div>
            <div className="position-relative mt-auto">
              <img
                className="img-fluid"
                // src="img/courses-1.jpg"
                src=""
                alt=""
              />
              {/* <div className="causes-overlay">
                <a className="btn btn-outline-primary" href="">
                  View More
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default About;
