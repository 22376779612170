import React from 'react'
import { useNavigate } from 'react-router-dom';
import company from "../../data/address.json"

function Privacy() {
    const navigate = useNavigate();
  return (
    <div id="about-div">
    <div className="container-xxl py-5">
    <div className="row g-4 justify-content-center">
        <div className="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
          <div className="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
            <div className="text-center p-4 pt-0">
              <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                <small>Privacy Policy</small>
              </div>
              <h5 className="mb-3"></h5>
              <p>{company.privecy}</p>
              {/* <div>Ph:</div> */}
              {/* <p className="">Email:</p> */}
              {/* <div className="causes-progress bg-white p-3 pt-2">
                        <div className=" justify-content-between"></div>
                      </div> */}
            </div>
            <div className="position-relative mt-auto">
              <img
                className="img-fluid"
                // src="img/courses-1.jpg"
                src=""
                alt=""
              />
              {/* <div className="causes-overlay">
                <a className="btn btn-outline-primary" href="">
                  View More
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
        
        
      </div>

    </div>

     
    </div>
  )
}

export default Privacy