import React from "react";
import { Link } from "react-router-dom";

function LocalNavbar() {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          display:"block"
        }}
        className="container-fluid Localfixed-top bg-dark shadow px-0 wow fadeIn"
      >
        <nav
          className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
            <h1 className="fw-bold text-primary m-0">
              <img width={250} src="img/logo.png" />
            </h1>
          </a>
          <button
            type="button"
            className="navbar-toggler me-4"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <Link to={"/"} className="nav-item nav-link active">
                Home
              </Link>
              <Link to={"/about"} className="nav-item nav-link">
                About
              </Link>
              <Link to={"/products"} className="nav-item nav-link ">
                Products
              </Link>

              <Link to={"/contact"} className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <div className="d-none d-lg-flex ms-2"></div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default LocalNavbar;
