import React, { useState } from "react";
import branch from "../../data/branch.json";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

function Caues() {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedBranch, setSelectedBranch] = useState({});
  // let branch=Array(8).fill({id:1})
  console.log(branch);
  const onHide = () => setModalShow(false);
  const navigate = useNavigate();
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
            <h1 className="display-6 mb-5">OUR BRANCHES</h1>
          </div>
          <div className="row g-4 justify-content-center">
            {branch.map((d) => {
              return (
                <div
                  className="col-lg-4 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="causes-item d-flex flex-column bg-light border-top border-5 border-primary rounded-top overflow-hidden h-100">
                    <div className="text-center p-4 pt-0">
                      <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
                        <small>{d.place}</small>
                      </div>
                      <h5 className="mb-3">{d.name}</h5>
                      <p>{d.address}</p>
                      <div>Ph:{d.phone}</div>
                      <p className="">Email:{d.email}</p>
                      {/* <div className="causes-progress bg-white p-3 pt-2">
                        <div className=" justify-content-between"></div>
                      </div> */}
                    </div>
                    <div className="position-relative mt-auto">
                      <img
                        className="img-fluid"
                        // src="img/courses-1.jpg"
                        src={d?.img ? d?.img : "img/branch/carousel-2.jpg"}
                        alt=""
                      />
                      <div className="causes-overlay">
                        <a
                          className="btn btn-outline-primary"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedBranch(d);
                            setModalShow(true);
                          }}
                        >
                          View More
                          <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                            <i className="fa fa-arrow-right" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Kyries Ventures
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            {selectedBranch?.name} {selectedBranch?.place}
          </h4>
          <p>{selectedBranch?.address}</p>
          <p>{selectedBranch?.email}</p>
          <p>{selectedBranch?.phone}</p>
          <p></p>
          <div className="d-flex">
            <a
              className="btn btn-outline-primary py-2 px-3"
              href=""
              onClick={(e) => {
                e.preventDefault();
                navigate("/products");
              }}
            >
              Our Products
              <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                <i className="fa fa-arrow-right" />
              </div>
            </a>
          </div>
          {/* <div className="d-flex">
            <a
              data-mdb-ripple-init=""
              className="btn btn-primary"
              style={{ backgroundColor: "#25d366" }}
              href="#!"
              role="button"
            >
              <i className="fab fa-whatsapp" />
            </a>
            <a
              data-mdb-ripple-init=""
              className="btn btn-primary"
              style={{ backgroundColor: "#25d366" }}
              href="#!"
              role="button"
            >
              <i className="fab fa-whatsapp" />
            </a>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Caues;
