import React, { useEffect } from 'react'
import Navbar from '../combonent/Navbar/Navbar'
import Carousel from '../combonent/Carousel/Carousel'
import Footer from '../combonent/Footer/Footer'
import LocalNavbar from '../combonent/Navbar/LocalNavbar'
import Team from '../combonent/Team/Team'
import LocalTeam from '../combonent/LoacalTeam/LocalTeam'
import { useLocation } from 'react-router-dom'
import Card from '../combonent/LoacalTeam/Card'

function TeamPage() {
 
  useEffect(() => {
    const element = document.getElementById('localTeam');
    if (element) {
      const yOffset = -50;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);
  
  return (
    <div>
          <Navbar/>
         <Carousel/>
        
         <Card/>
         <Footer/>
    </div>
  )
}

export default TeamPage