import React from "react";
import "./style.css";
import company from '../../data/address.json'

function Contact() {
  return (
    <div>
      <main id="contact-div">
        <div className="container py-5">
          <div className="row g-5">
            {/* Contact Information Block */}
            <div className="col-xl-6">
              <div className="row row-cols-md-2 g-4">
                <div
                  className="aos-item"
                  data-aos="fade-up"
                  data-aos-delay={200}
                >
                  <div className="aos-item__inner">
                    <div className="bg-light hvr-shutter-out-horizontal d-block p-3">
                      <div className="d-flex justify-content-start">
                        <i className="fa-solid fa-envelope h3 pe-2" />
                        <span className="h5">Email</span>
                      </div>
                      <span>{company.email}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="aos-item"
                  data-aos="fade-up"
                  data-aos-delay={400}
                >
                  <div className="aos-item__inner">
                    <div className="bg-light hvr-shutter-out-horizontal d-block p-3">
                      <div className="d-flex justify-content-start">
                        <i className="fa-solid fa-phone h3 pe-2" />
                        <span className="h5">Phone</span>
                      </div>
                      <span>{company.phone}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="aos-item mt-4"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="aos-item__inner">
                  <div className="bg-light hvr-shutter-out-horizontal d-block p-3">
                    <div className="d-flex justify-content-start">
                      <i className="fa-solid fa-location-pin h3 pe-2" />
                      <span className="h5">Office location</span>
                    </div>
                    <span>{company.address}</span>
                  </div>
                </div>
              </div>
              <div className="aos-item" data-aos="fade-up" data-aos-delay={800}>
                <div className="mt-4 w-100 aos-item__inner">
                  <iframe
                    className="hvr-shadow"
                    width="100%"
                    height={345}
                    frameBorder={0}
                    scrolling="no"
                    marginHeight={0}
                    marginWidth={0}
                    // src="https://maps.google.com/maps?width=100%25&height=300&hl=en&q=1%20Grafton%20Street,%20Dublin,%20Ireland+()&t=&z=14&ie=UTF8&iwloc=B&output=embed"
                    src={company.gmap}
                  >
                    &lt;a
                    href="https://www.maps.ie/distance-area-calculator.html"&gt;measure
                    acres/hectares on map&lt;/a&gt;
                  </iframe>
                </div>
              </div>
            </div>
            {/* Contact Form Block */}
            <div className="col-xl-6">
              <h2 className="pb-4">Leave a message</h2>
              <div className="row g-4">
                <div className="col-6 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="John"
                  />
                </div>
                <div className="col-6 mb-3">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Doe"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Phone
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder={+1234567890}
                />
              </div>
              {/* <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Country
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option value={1}>USA</option>
                  <option value={2}>Non USA</option>
                </select>
              </div> */}
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  defaultValue={""}
                />
              </div>
              <button type="button" className="btn btn-dark">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Contact;
