
import React from 'react'
import Navbar from "../combonent/Navbar/Navbar";
import Carousel from '../combonent/Carousel/Carousel';
import Footer from '../combonent/Footer/Footer';
import Privacy from '../combonent/Privacy/Privacy';

function PrivacyPage() {
  return (
    <>
     <Navbar />
    <Carousel />
    <Privacy/>
    <Footer />
    
    
    </>
   
  )
}

export default PrivacyPage