import React, { useEffect } from 'react'
import Navbar from '../combonent/Navbar/Navbar'
import Carousel from '../combonent/Carousel/Carousel'
import About from '../combonent/About/About'
import Footer from '../combonent/Footer/Footer'
import Product from '../combonent/Product/Product'

function ProductPage() {
  useEffect(() => {
    const element = document.getElementById('products-div');
    if (element) {
      const yOffset = -50;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }})
  return (
    <div>
        <Navbar />
      <Carousel />
      <Product />
      <Footer />
    </div>
  )
}

export default ProductPage