import React from "react";
import teams from "../../data/tesm.json";

function Team() {
  return (
    <div>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: 500 }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Team Members
            </div>
            <h1 className="display-6 mb-5">
              Let's Meet With Our Ordinary Soldiers
            </h1>
          </div>
          <div className="row g-4">
            {teams.map((p) => {
              return (
                <div
                  className="col-lg-3 col-md-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="team-item position-relative rounded overflow-hidden">
                    <div className="overflow-hidden">
                      <img className="img-fluid" src={p.img} alt="" />
                    </div>
                    <div className="team-text bg-light text-center p-4">
                      <h5>{p.name}</h5>
                      <p className="text-primary">{p.designation}</p>
                      <div className="team-social text-center">
                        <a className="btn btn-square" href={p.facebook}>
                          <i className="fab fa-facebook-f" />
                        </a>
                        <a className="btn btn-square" href="">
                          <i className="fab fa-twitter" />
                        </a>
                        <a className="btn btn-square" href={p.insta}>
                          <i className="fab fa-instagram" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
