import "./App.css";
import About from "./page/About";
import ErrorPage from "./page/Error";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./page/Home";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Routes,
  useLocation,
} from "react-router-dom";
import TeamPage from "./page/TeamPage";

import { useEffect } from "react";
import ProductPage from "./page/ProductPage";
import ContactPage from "./page/ContactPage";
import TermsPage from "./page/TermsPage";

import PrivacyPage from "./page/PrivacyPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<ErrorPage />} path="*" />
          <Route element={<Home />} path="/" />
          <Route element={<About />} path="/about" />
          <Route element={<TeamPage />} path="/team" />
          <Route element={<ProductPage />} path="/products" />
          <Route element={<ContactPage />} path="/contact" />
          <Route element={<TermsPage />} path="/terms-conditions" />
          <Route element={<PrivacyPage />} path="/privacy-policy" />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
