import React, { useEffect, useState,CSSProperties } from 'react'
import Spinner from '../combonent/Spinner/Spinner'
import Navbar from '../combonent/Navbar/Navbar'
import Header from '../combonent/Header/Header'
import Caues from '../combonent/Causes/Caues'
import Footer from '../combonent/Footer/Footer'
import Carousel from '../combonent/Carousel/Carousel'
import About from '../combonent/About/About'
import Servieces from '../combonent/Services/Servieces'
import Team from '../combonent/Team/Team'
import { ClipLoader } from 'react-spinners'

function Home() {
  
// const override: CSSProperties = {
//   display: "block",
//   // margin: "0 auto",
//   borderColor: "yellow",
// };
  const [loading,setLoading]=useState(false)
  let [color, setColor] = useState("#ffffff");
  useEffect(()=>{
  setLoading(false)
  setTimeout(()=>{
    setLoading(false)
  },5000)
  },[])
  return (
    <div>
      {
        loading? <ClipLoader
        color={color}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />:<>
       {/* <Spinner/> */}
       <Navbar/>
        {/* <Header/> */}
        <Carousel/>
        {/* <About/> */}
        <Caues/>
        {/* <Servieces/> */}
        <Team/>
        <Footer/>
      </>
      }
       
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
  <i className="bi bi-arrow-up" />
</a>
    </div>
  )
}

export default Home