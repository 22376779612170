import React, { useEffect } from 'react'
import ErrorRoute from '../combonent/404/Error'
import Navbar from '../combonent/Navbar/Navbar'
import Carousel from '../combonent/Carousel/Carousel'
import Footer from '../combonent/Footer/Footer'
import LocalNavbar from '../combonent/Navbar/LocalNavbar'

function ErrorPage() {
  useEffect(() => {
    const element = document.getElementById('error-div');
    if (element) {
      const yOffset = -50;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);
  return (
    <>
    <Navbar/>
    <br/>
    {/* <Navbar/> */}
    <Carousel/>
    <ErrorRoute/>
    <Footer/>
    </>
  )
}


export default ErrorPage