import React from 'react'
import Navbar from "../combonent/Navbar/Navbar";
import Carousel from '../combonent/Carousel/Carousel';
import Footer from '../combonent/Footer/Footer';
import Terms from '../combonent/Terms/Terms';
function TermsPage() {
  return (
    <div>
          <Navbar />
      <Carousel />
      <Terms/>
      <Footer />
    </div>
  )
}

export default TermsPage