import React from "react";
import "./style.css";
import localTeam from "../../data/localTeam.json";
function Card() {
  return (
    <div
    id='localTeam'
    className="text-center mx-auto mb-5 wow fadeInUp"
    data-wow-delay="0.1s"
    // style={{ maxWidth: 500 }}
    
  >

<h1 className="display-6 mb-5">
            Team Members
            </h1>
 
    <div   className="loaclTeam-body">
      <div className="container">
        <div className="row mt-n1-9">
          {localTeam.map((member) => {
            return (
              <div
                className="col-md-6 col-xl-3 mt-1-9 wow fadeInUp"
                data-wow-delay=".2s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.2s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="team-style07">
                  <div className="team-thumb mb-1-9">
                    <div className="thumb">
                      <img
                        src={member.img}
                        className="rounded-circle"
                        alt="..."
                      />
                    </div>
                    <div className="team-social">
                      <ul className="styled-icons">
                        <li>
                          <a className="styled-icons-item" href="#!">
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a className="styled-icons-item" href="#!">
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a className="styled-icons-item" href="#!">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h3 className="h5 mb-1">
                    <a href="#!">{member.name}</a>
                  </h3>
                  <p className="mb-0">{member?.designation?member?.designation:"HOD"}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    </div>
  );
}

export default Card;
