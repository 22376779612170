import React, { useEffect } from "react";
import Navbar from "../combonent/Navbar/Navbar";
import LocalNavbar from "../combonent/Navbar/LocalNavbar";
import Footer from "../combonent/Footer/Footer";
import Carousel from "../combonent/Carousel/Carousel";
import About from "../combonent/About/About";

function AboutPage() {
  useEffect(() => {
    const element = document.getElementById("about-div");
    if (element) {
      const yOffset = -50;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Carousel />
      <About />
      <Footer />
    </div>
  );
}

export default AboutPage;
