import React from "react";
import company from "../../data/address.json";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h1 className="fw-bold text-primary mb-4">
                {/* Chari<span className="text-white">Team</span> */}
                <img width={150} src="img/logo.png" />
              </h1>

              <p>
                Transforming consumer experiences worldwide with our diversified
                retail solutions
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-square me-1" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square me-1" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square me-1" href="">
                  <i className="fab fa-youtube" />
                </a>
                <a className="btn btn-square me-0" href="">
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Address</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                {company.city},{company.state}, {company.country}
              </p>
              <p>
                <i className="fa fa-phone-alt me-3" />
                {company.phone}
              </p>
              <spam>
                <i className="fa fa-envelope me-3" />
                kyriesventuresllp@gmail.com
              </spam>
            </div>
            <div className="col-lg-4 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/about");
                }}
              >
                About Us
              </a>
              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/contact");
                }}
              >
                Contact Us
              </a>
              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/products");
                }}
              >
                Our Products
              </a>
              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/team");
                }}
              >
                Team
              </a>
              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/terms-conditions");
                }}
              >
                Terms &amp; Condition
              </a>

              <a
                className="btn btn-link"
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </a>
            </div>
            {/* <div className="col-lg-3 col-md-6">
        <h5 className="text-light mb-4">Newsletter</h5>
        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
        <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
          <input
            className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
            type="text"
            placeholder="Your email"
          />
          <button
            type="button"
            className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
          >
            SignUp
          </button>
        </div>
      </div> */}
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © <a href="#">Kyries Ventures</a>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
