
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import $ from 'jquery';
function Navbar() {
  const location=useLocation()
  console.log(location);
  useEffect(()=>{
    $(window).scroll(function () {
      if ($(window).width() < 992) {
          if ($(this).scrollTop() > 45) {
              $('.fixed-top').addClass('bg-dark shadow');
          } else {
              $('.fixed-top').removeClass('bg-dark shadow');
          }
      } else {
          if ($(this).scrollTop() > 45) {
              $('.fixed-top').addClass('bg-dark shadow').css('top', -45);
          } else {
              $('.fixed-top').removeClass('bg-dark shadow').css('top', 0);
          }
      }
  });
  },[location])
  return (
    <div><div
    className="container-fluid fixed-top px-0 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
      <div className="col-lg-6 px-5 text-start">
        <small>
          <i className="fa fa-map-marker-alt me-2" />
          Perinthalmanna, Kerala, India
        </small>
        <small className="ms-4">
          <i className="fa fa-envelope me-2" />
          kyriesventuresllp@gmail.com
        </small>
      </div>
      <div className="col-lg-6 px-5 text-end">
        <small>Follow us:</small>
        <a className="text-white-50 ms-3" href="">
          <i className="fab fa-facebook-f" />
        </a>
        <a className="text-white-50 ms-3" href="">
          <i className="fab fa-twitter" />
        </a>
        <a className="text-white-50 ms-3" href="">
          <i className="fab fa-linkedin-in" />
        </a>
        <a className="text-white-50 ms-3" href="">
          <i className="fab fa-instagram" />
        </a>
      </div>
    </div>
    <nav
      className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
        <h1 className="fw-bold text-primary m-0">
         
          <img width={250} src="img/logo.png"/>
        </h1>
     
      </a>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link to={"/"} className="nav-item nav-link active">
            Home
          </Link>
          <Link to={"/about"} className="nav-item nav-link">
            About
          </Link>
          <Link to={"/team"} className="nav-item nav-link ">
            Team
          </Link>
          <Link to={"/products"} className="nav-item nav-link ">
            Products
          </Link>
         
          <Link to={"/contact"} className="nav-item nav-link">
            Contact
          </Link>
        </div>
        <div className="d-none d-lg-flex ms-2">
      
        </div>
      </div>
    </nav>
  </div>
  </div>
  )
}

export default Navbar